import React, { useRef, useEffect, useState } from 'react';
import { Container, Row, Button } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../assets/images/andamanlogobg.png';
import './header.css';

const nav_links = [
  {
    path: '/',
    display: 'Home',
  },
  {
    path: '/about',
    display: 'About',
  },
  {
    path: '/tours',
    display: 'Tours',
  },
  // {
  //   path: '/login',
  //   display: 'Login',
  // },
  // {
  //   path: '/register',
  //   display: 'Register',
  // },

];

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const headerRef = useRef(null);

  const stickyHeaderFunc = () => {
    window.addEventListener('scroll', () => {
      if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        headerRef.current.classList.add('sticky__header');
      } else {
        headerRef.current.classList.remove('sticky__header');
      }
    });
  };

  useEffect(() => {
    stickyHeaderFunc();

    return () => {
      window.removeEventListener('scroll', stickyHeaderFunc);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header className={`header ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`} ref={headerRef}>
      <Container>
        <Row>
          <div className="nav_wrapper d-flex align-items-center justify-content-between">
            <div className='logo'>
              <NavLink to='/'><img src={logo} alt="" /></NavLink>
            </div>
            <div className={`navigation ${isMobileMenuOpen ? 'show__menu' : ''}`}>
              <ul className='menu d-flex align-items-center gap-5'>
                {nav_links.map((item, index) => (
                  <li className='nav__item' key={index}>
                    <NavLink
                      to={item.path}
                      className={(navClass) => (navClass.isActive ? "active__link" : "")}
                      onClick={closeMobileMenu} // Close mobile menu when a menu item is clicked
                    >
                      {item.display}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className='nav__right d-flex align-items-center gap-4'>
              <div className='nav__btns d-flex align-items-center gap-4'>
                {/* <Button className='btn secondary__btn'><Link to='/login'>Login</Link></Button>
                <Button className='btn primary__btn'><Link to='/register'>Register</Link></Button> */}
                {/* <Button className='btn secondary__btn'>Call us: +91 8630999103</Button>
                <Button className='btn primary__btn'>thakuraman8630@gmail.com</Button> */}
                <Button className='btn secondary__btn'><a href="tel:+918285731078">Call us: +91 8285731078</a></Button>
                <Button className='btn primary__btn'><a href="mailto:iconicandaman@gmail.com">Email</a></Button>


              </div>
              <span className='mobile__menu' onClick={toggleMobileMenu}>
                <i className={`ri-menu-line ${isMobileMenuOpen ? 'active' : ''}`}></i>
              </span>
            </div>
          </div>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
