import React from 'react';
import ServicesCard from './ServiceCard';
import {Col} from "reactstrap";

import weatherImg from '../assets/images/weather.png';
import guideImg from '../assets/images/guide.png';
import customizationImg from '../assets/images/customization.png';

const servicesData=[
{
    imgUrl: weatherImg,
    title: "Calculate Weather",
    desc:"We offer our best services for calculating weather on our website.",
  },
  {
    imgUrl: guideImg,
    title: "Best Tour Guide",
    desc:"Discover your dream destination with the best tour guide services.",
  },
  {
    imgUrl:customizationImg,
    title: "CustomizationImg",
    desc:"Experience personalized magic with CustomizationImg for your image needs."
  },
  
]



const ServicesList = () => {
  return (
    <>
    {servicesData.map((item,index)=>(
        <Col lg="3" key={index}>
            <ServicesCard item={item}/>
        </Col>
    ))}
    </>
  )
}

export default ServicesList    