import React from 'react';
import Slider from 'react-slick';
import ava01 from '../../assets/images/ava1.jpg';
import ava02 from '../../assets/images/ava5.jpg';
import ava03 from '../../assets/images/ava6.jpg';
import ava04 from '../../assets/images/ava8.jpg';
import ava05 from '../../assets/images/ava7.jpg';
import ava06 from '../../assets/images/ava3.jpg';

const Testimonials = () => {
    const settings={
        dots:true,
        infinite:true,
        autoplay:true,
        speed:1000,
        swipeToSlide:true,
        autoplaySpeed:2000,
        slidesToShow:3,

        responsive:[
            {
                breakpoint:992,
                settings:{
                    slidesToShow:2,
                    slidesToScroll:1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 576,
                settings:{
                    slidesToShow:1,
                    slidesToScroll:1,
                },
            },
        ]
    }

  return <Slider {...settings}>
    <div className="testimonial py-4 px-3">
    <p>
        Andaman & Nicobar are a group of picturesque Islands, big and small, inhabited and uninhabited, a total of 572 islands, islets and rocks lying in the South Eastern Part of the Bay of Bengal. They lie along an arc in long and narrow broken chain.
            </p>

        <div className="d-flex aloign-items-center gap-4 mt-3">
            <img src={ava01} className='w-25 h-25 rounded-2' alt="" />
            <div>
                <h6 className='mb-0 mt-3'>Sakshi</h6>
                <p>Customer</p>
            </div>
        </div>
        </div>
   
        <div className="testimonial py-4 px-3">
        <p>
        The great Andamans Coral reefs, were one of the biggest eye catchers. And also the wild forest , tribals and marine creatures attracted me the most. I recomend it for every one who want space to breath frech air and freedom to visit Andaman and Nicobar islands.
            </p>

        <div className="d-flex aloign-items-center gap-4 mt-3">
            <img src={ava02} className='w-25 h-25 rounded-2' alt="" />
            <div>
                <h6 className='mb-0 mt-3'>Rishabh Nigam</h6>
                <p>Customer</p>
            </div>
        </div>
        </div>

        <div className="testimonial py-4 px-3">
        <p>
        Mahatma Gandhi Marine National Park is a national park of India which is located around 29 kilometers away from Port Blair. It is owned by the South Andaman administrative district, which is a part of the union territory of India.
            </p>

        <div className="d-flex aloign-items-center gap-4 mt-3">
            <img src={ava03} className='w-25 h-25 rounded-2' alt="" />
            <div>
                <h6 className='mb-0 mt-3'>Aman Singh</h6>
                <p>Customer</p>
            </div>
        </div>
        </div>

        <div className="testimonial py-4 px-3">
        <p>
        Every Indian should visit at least once in lifetime to Andaman. Most of population is from Bengal and Tamil and speak Hindi.Andaman is very good place to visit. Havlock beaches are treated most beautiful beaches in the world.
            </p>

        <div className="d-flex aloign-items-center gap-4 mt-3">
            <img src={ava04} className='w-25 h-25 rounded-2' alt="" />
            <div>
                <h6 className='mb-0 mt-3'>Ayushi</h6>
                <p>Customer</p>
            </div>
        </div>
        </div>

        <div className="testimonial py-4 px-3">
        <p>
        The Cellular Jail National Memorial is the place, whose historical darks secrets offer goose-bumps till the date. However, it seems depressing to note the dark part of the struggles and sacrifices of many Indians associated with the Cellular Jail.
            </p>

        <div className="d-flex aloign-items-center gap-4 mt-3">
            <img src={ava05} className='w-25 h-25 rounded-2' alt="" />
            <div>
                <h6 className='mb-0 mt-3'>Shubham Singh</h6>
                <p>Customer</p>
            </div>
        </div>
        </div>

        <div className="testimonial py-4 px-3">
        <p>
        The Anthropological Museum of Port Blair is considered to be one of the highly maintained and pretty sagacious about local tribal communities. These tribal communities are considered to be the oldest islanders in the world and that was amazing.
            </p>

        <div className="d-flex aloign-items-center gap-4 mt-3">
            <img src={ava06} className='w-25 h-25 rounded-2' alt="" />
            <div>
                <h6 className='mb-0 mt-3'>Deepti</h6>
                <p>Customer</p>
            </div>
        </div>
        </div>


  </Slider>
}

export default Testimonials;