import galleryImg01 from '../../assets/images/tour-img001.jpg';
//import galleryImg02 from '../../assets/images/gallery-02.jpg';
import galleryImg02 from '../../assets/images/gallary08.jpg';
//import galleryImg03 from '../../assets/images/gallery-03.jpg';
import galleryImg03 from '../../assets/images/gallary09.jpg';
import galleryImg04 from '../../assets/images/gallary10.jpg';
//import galleryImg04 from '../../assets/images/gallery-04.jpg';
import galleryImg05 from '../../assets/images/island1.jpg';
//import galleryImg05 from '../../assets/images/island1.jpg';
//import galleryImg05 from '../../assets/images/gallery-05.jpg';
import galleryImg06 from '../../assets/images/gallary11.jpg';
//import galleryImg06 from '../../assets/images/gallery-06.jpg';
import galleryImg07 from '../../assets/images/gallery-07.jpg';
import gallary13 from '../../assets/images/island5.jpg';
import gallary10 from '../../assets/images/island7.jpg';
import gallary11 from '../../assets/images/gallary13.jpg';
import gallary12 from '../../assets/images/island2.jpg';
import gallary14 from '../../assets/images/island3.jpg';
import gallary15 from '../../assets/images/island4.jpg';


const galleryImages=[
    galleryImg01,
    galleryImg02,
    galleryImg03,
    galleryImg04,
    galleryImg05,
    galleryImg06,
    galleryImg07,
    gallary10,
    gallary11,
    gallary13,
    gallary12,
    gallary14,
    gallary15,
]

export default galleryImages;