import React, { useRef, useState } from 'react';
import '../styles/tour-details.css';
import { Container, Row, Col, Form, ListGroup } from 'reactstrap';
import { useParams } from 'react-router-dom';
import tourData from '../assets/data/tours'
import calculateAvgRating from '../utils/avgRating';
import avatar from "../assets/images/avatar.jpg";
import Booking from '../components/Booking/Booking';
import Newsletter from '../shared/Newsletter';

const TourDetails = () => {

  const { id } = useParams();
  const reviewMsgRef = useRef('')
  const [tourRating, setTourRating] = useState(null)

  //this is an static data later we will call our API and load our data from database

  const tour = tourData.find(tour => tour.id === id)

  //destructure propertices from tour objects

  const { photo, title, desc, price, address, reviews, city, distance, maxGroupSize } = tour;
  const { day1, day2, day3, day4, day5, day6, day7 } = desc;
  const { day1Title, day1Desc } = day1;
  const { day2Title, day2Desc, } = day2;
  const { day3Title, day3Desc, } = day3;
  const { day4Title, day4Desc, } = day4;
  const { day5Title, day5Desc, } = day5;
  const { day6Title, day6Desc, } = day6;
  const { day7Title, day7Desc, } = day7;
  //console.log(day1)

  const { totalRating, avgRating } = calculateAvgRating(reviews);

  //format date
  const options = { day: "numeric", month: "long", year: "numeric" };

  //submit request to the server

  const submitHandler = e => {
    e.preventDefault()
    const reviewText = reviewMsgRef.current.value
    //alert(`${reviewText},${tourRating}`);

    // later will call our api

  };

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col lg='8'>
              <div className="tour__content">
                <img src={photo} alt="" />

                <div className="tour__info">
                  <h2>{title}</h2>

                  <div className='d-flex align-item-center gap-5'>

                    <span className='tour__rating d-flex align-items-center gap-1'>

                      <i class="ri-star-s-fill" style={{ color: "var(--secondary-color" }}></i>

                      {avgRating === 0 ? null : avgRating}{totalRating === 0 ? (
                        "Not rated"
                      ) : (
                        <span>({reviews?.length})</span>
                      )}
                    </span>

                    <span>
                      <i class="ri-map-pin-user-fill"></i>
                      {address}
                    </span>
                  </div>

                  <div className="tour__extra-details">
                    <span><i class="ri-map-pin-2-line"></i>{city}</span>

                    <span>
                      <i class="ri-money-dollar-circle-line"></i>${price}/ per person
                    </span>

                    <span><i class="ri-map-pin-time-line"></i>{distance} K/M
                    </span>

                    <span><i class="ri-group-line"></i>{maxGroupSize} people
                    </span>

                  </div>
                  <h5>Description</h5>
                  {/* <h4>Day 1:</h4> */}
                  <h6>{day1Title}</h6>
                  <p>{day1Desc}</p>
                  <h6>{day2Title}</h6>
                  <p>{day2Desc}</p>
                  <h6>{day3Title}</h6>
                  <p>{day3Desc}</p>
                  <h6>{day4Title}</h6>
                  <p>{day4Desc}</p>
                  <h6>{day5Title}</h6>
                  <p>{day5Desc}</p>
                  <h6>{day6Title}</h6>
                  <p>{day6Desc}</p>
                  <h6>{day7Title}</h6>
                  <p>{day7Desc}</p>
                </div>

                {/******* tour reviews section start  *******/}

                <div className="tour__reviews mt-4">
                  <h4>Reviews ({reviews?.length} reviews)</h4>

                  <Form onClick={submitHandler}>
                    <div className='d-flex align-items-center gap-3 mb-4 rating__group'>
                      <span onClick={() => setTourRating(1)}>
                        1 <i class="ri-star-s-fill"></i>
                      </span>
                      <span onClick={() => setTourRating(2)}>2 <i class="ri-star-s-fill"></i>
                      </span>
                      <span onClick={() => setTourRating(3)}>3 <i class="ri-star-s-fill"></i>
                      </span>
                      <span onClick={() => setTourRating(4)}>4 <i class="ri-star-s-fill"></i>
                      </span>
                      <span onClick={() => setTourRating(5)}>5 <i class="ri-star-s-fill"></i>
                      </span>
                    </div>

                    <div className="review__input">
                      <input type="text" ref={reviewMsgRef} placeholder='share your thoughts' required />
                      <button className='btn primary__btn text-white' type='submit'>Submit
                      </button>
                    </div>
                  </Form>

                  <ListGroup className='user__reviews'>
                    {/* {
                      reviews?.map(review => ( */}
                        <div className="review__item">
                          <img src={avatar} alt="" />
                          <div className="w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                <h5>Aman</h5>
                                <p>
                                  {new Date("07-31-2023").toLocaleDateString('en-US',
                                    options
                                  )}
                                </p>
                              </div>
                              <span className='d-flex align-items-center'>5
                                <i class='ri-star-s-fill'></i>
                              </span>
                            </div>
                            <h6>Amazing tour</h6>
                          </div>
                        </div>
                      {/* ) */}
                      {/* ) */}
                    {/* } */}
                  </ListGroup>
                </div>
                {/******* tour reviews section end    *******/}
              </div>
            </Col>
            <Col lg='4'>
              <Booking tour={tour} avgRating={avgRating} />
            </Col>
          </Row>
        </Container>
      </section>
      <Newsletter />
    </>
  )
}

export default TourDetails;