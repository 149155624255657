import React from 'react';
import Newsletter from '../shared/Newsletter';
import { Container,Row,Col } from 'reactstrap';
import Subtitle from './../shared/Subtitle';
import heroImg03 from '../assets/images/beach1.jpg';
import heroImg02 from '../assets/images/beach2.jpg';
import heroimg01 from '../assets/images/beach3.jpg';

const About = () => {
  return (
   <>
   <section>
    <Container>
      <Row>
        <Col lg='6'>
          <div className='hero__content'>
            <div className='hero__subtitle d-flex align-items-center'>
              <Subtitle subtitle={'Know Before You Go'}/>
              {/* <img src={worldImg} alt="" /> */}
            </div>
            <h1> <span className='highlight'> Iconic Andaman Holiday </span>opens the door to creating memories </h1>
            <p>
            Welcome to <b>IconicAndamanHoliday.com</b> , your gateway to an unforgettable journey through the enchanting Andaman & Nicobar Islands. We are your trusted partner in curating experiences that combine the serenity of the shore with the vibrancy of the city.
            </p>
            <p>At Iconic Andaman Holiday, we understand the unique allure of the Andaman & Nicobar Islands, where azure waters meet lush green landscapes, and pristine beaches embrace rich cultural heritage. With our diverse range of thoughtfully crafted packages, we ensure that your visit to this tropical paradise is a seamless blend of relaxation, adventure, and discovery.</p>
          </div>
        </Col>
        <Col lg='2'>
          <div className='hero__img-box'>
            <img src={heroImg02} alt="" />
          </div>
        </Col>
        <Col lg='2'>
          <div className='hero__img-box mt-4'>
            <img src={heroImg03} alt=''/>
          </div>
        </Col>
        <Col lg='2'>
          <div className='hero__img-box mt-5'>
            <img src={heroimg01} alt="" />
          </div>
        </Col>
        {/* <SearchBar/> */}
      </Row>
    </Container>
  </section>

  <section>
    <Container>
      <Row>
        <Col lg='5'>
          <h5 className='services__subtitle'>What we server</h5>
          <h2 className='services_title'>We offer our best Packages: </h2>
          <br />
        </Col>
      <ul>
        <li><strong>Shore & the City:</strong> Discover the dynamic blend of nature and urban life in Port Blair, the gateway to the Andamans. Dive into rich history and vibrant culture, all while experiencing the beauty of the Andaman sea.</li>
        <br />

        <li><strong>Andaman Super Saver:</strong> Uncover incredible value with our super saver package that includes must-see attractions, leisure, and relaxation.</li>
        <br />
        
        <li><strong>Andaman Best Seller:</strong> Handpicked experiences that showcase the best of Andaman's natural beauty, from pristine beaches to underwater wonders.</li>
        <br />

        <li><strong>Andaman Fun Filled:</strong> Designed for adventure seekers and thrill enthusiasts, this package promises non-stop excitement and adrenaline-pumping activities.</li>
        <br />

        <li><strong>Andamans - Chota Break:</strong> Short on time but longing for an Andaman escape? Our Chota Break package offers a quick yet fulfilling getaway.</li>
        <br />

        <li><strong>Andamans Blue:</strong> Immerse yourself in the calming blue of the Andaman sea, with snorkeling, diving, and water sports experiences that leave you refreshed and rejuvenated.</li>
         <br />

        <li><strong>Essence of Andamans:</strong> For those seeking the soul of the islands, this package delves deep into the cultural heritage, local flavors, and off-the-beaten-path experiences</li>
         <br />
        <li><strong>Andamans - Iconic Nature:</strong> Discover the pristine and untouched Andaman Islands with a focus on wildlife, eco-tourism, and nature conservation.</li>
        {/* Repeat this pattern for all other packages */}
      </ul>
      <p>
      At <b>Iconic Andaman Holiday</b>, our commitment goes beyond providing tours; we strive to create memories. Our knowledgeable guides, handpicked accommodations, and top-notch services ensure that your journey is not just a trip but an enriching, unforgettable experience.
      </p>
      <p>
      With <b>IconicAndamanHoliday.com</b>, you embark on a journey filled with mesmerizing sunsets, thrilling adventures, delectable cuisine, and cherished moments. Let us be your trusted companion on your quest to explore the iconic beauty of the Andaman & Nicobar Islands.
      </p>
      <h4 className='services_title'>Join us, and let the Andamans leave an indelible mark on your heart. </h4>
      </Row>
    </Container>
  </section>
    <Newsletter/>
   </>
    
  );
};

export default About;
