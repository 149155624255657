import tourImg01 from "../images/tour-img001.jpg";
import tourImg02 from "../images/island2.jpg";
import tourImg03 from "../images/island3.jpg";
import tourImg04 from "../images/island4.jpg";
import tourImg05 from "../images/island5.jpg";
import tourImg06 from "../images/island9.jpg";
import tourImg07 from "../images/island11.jpg";
import island12 from "../images/island12.jpg";

const tours = [
  {
    id: "01",
    title: "Shore & the City- Port Blair",
    city: "Port Blair",
    address: '3 Nights / 4 Days | 3 Nights Port Blair/ Day trip to Havelock Island',
    distance: 300,
    price: 99,
    maxGroupSize: 10,
    desc: {
      day1:{
        day1Title:" Day 1: Arrival at Port Blair",
        day1Desc:"Arrival to Port Blair. Our representative will meet and assist you at the Veer Savarkar Airport. Board the waiting vehicles, drive to your hotel and check in. First half of the day is at leisure. Later in the afternoon visit Cellular jail followed by light and sound show. The Cellular Jail has a pride of place in India’s freedom struggle. Many a stalwart were imprisoned here. Declared a national memorial, this three-story prison constructed by Britishers in 1906, is a pilgrimage destination for freedom fighters. This colossal edifice has mutely witnessed the most treacherous of inhumane atrocities borne by the convicts, who were mostly freedom fighters. The day draws to a gentle close with the Sound and Light Show at the historic Cellular Jail which gives you a vivid experience of its strong association with the freedom fighters and their struggle for independence. Overnight at the Hotel."
      },
      day2:{
        day2Title:"Day 2: Havelock Island Visit Radha Nagar Beach & Back",
        day2Desc:"Early in the morning proceed to Harbor for onward journey to HAVELOCK the beautiful and world-famous Island. In Havelock guest can see and enjoy Asia 7th longest & beautiful beach i.e., Radha Nagar. In the evening, the guest will start return journey for Port Blair"
      },
      day3:{
        day3Title:"Day 3: North Bay (Coral Island) – Ross Island ",
        day3Desc:"Today after breakfast embark on a day long three islands cruise. One of the most sought-after activities. It covers a panorama of seven harbor that include the North Bay and Ross Island. It leaves a lasting impression. Visit Ross Island. Once the administrative headquarters of the British and capital of these Islands, the island is now under the Indian Navy. It stands now as a ruin of the bygone days with the old structures almost in debris. There is a small museum named Smritika, it holds photographs and the other antiques of the Britishers relevant to these islands. The island is famous for friendly flocks of Deer and lush green walking trails. North Bay, the water lovers may enjoy snorkeling or take a Glass Bottom boat ride (Optional on direct payment) in its shallow waters with a chance to see corals up close. Return to the hotel. Overnight at the Hotel."
      },
      day4:{
        day4Title:"Day 4: Depart from Port Blair",
        day4Desc:"Drop to Airport / Harbor for Return journey with wonderful memories."
      },
      day5:{
        day5Title:"",
        day5Desc:""
      },
      day6:{
        day6Title:"",
        day6Desc:""
      },
      day7:{
        day7Title:"",
        day7Desc:""
      }
      
    },

    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
      {
        name: "jhon doe",
        rating: 5,
      },
      {
        name: "jhon doe",
        rating: 4.6,
      },
      {
        name: "jhon doe",
        rating: 4.9,
      },
      
    ],
    avgRating: 4.5,
    photo: tourImg01,
    featured: false,
  },
  {
    id: "02",
    title: "Andaman Super Saver",
    city: "Port Blair",
    address: '4 Nights / 5 Days | 2 Nights Port Blair/ 2 Nights Havelock Islandd',
    distance: 300,
    price: 99,
    maxGroupSize: 10,
    desc: {
      day1:{
        day1Title:"Day 1: Arrival – Port Blair – Cellular Jail – Light & Sound Show",
        day1Desc:"Arrival to Port Blair. Our representative will meet and assist you at the Veer Savarkar Airport. Board the waiting vehicles, drive to your hotel and check in. First half of the day is at leisure. Later in the afternoon visit Cellular jail followed by light and sound show. The Cellular Jail has a pride of place in India’s freedom struggle. Many a stalwart were imprisoned here. Declared a national memorial, this three-story prison constructed by Britishers in 1906, is a pilgrimage destination for freedom fighters. This colossal edifice has mutely witnessed the most treacherous of inhumane atrocities borne by the convicts, who were mostly freedom fighters. The day draws to a gentle close with the Sound and Light Show at the historic Cellular Jail which gives you a vivid experience of its strong association with the freedom fighters and their struggle for independence. Overnight at the Hotel."
      },
      day2:{
        day2Title:"Day 2: Port Blair- Havelock",
        day2Desc:"Today after breakfast you will transfer to Jetty. Head to Havelock Island on board Cruise connecting the two islands! Havelock, about 54 kilometers northeast of Port Blair, the Island is a hidden jewel. Few Indians know of this stunning beach destination in their own backyard while heading for Phuket (in Thailand) across the waters of Andaman Sea. Havelock is a serene tropical island encompassing about 86 square kilometers. Its clear waters are rated among the best in the world for snorkeling and scuba diving. Close to 1000 different species of marine life inhabit the waters of the Andaman Islands. Scuba divers can see nudibranchs, sea slugs and snails, varieties of ghost pipe fish, jaw fish and a number of different crustaceans of all shapes and sizes. With its serene beaches, 40 meters visibility underwater, colorful coral reefs and ‘far from the maddening crowd’ appeal, Havelock Island has emerged as a dream destination among discerning travelers. Arrive at Havelock Jetty, you are met and assisted by our representative. Board the waiting vehicles and drive to the hotel. Check in at the hotel. Overnight at the Hotel."
      },
      day3:{
        day3Title:"Day 3: Havelock – Radha Nagar Beach ",
        day3Desc:"Today after breakfast, proceed for Radha Nagar Beach (or Beach No. 7) is the jewel in the crown of Havelock Island. Located on the western side of the island, it stands away from the cluster. The glorious white sand beach shot to global fame when Time magazine (in 2004) rated it as the finest in Asia. With lush tropical forest rising on a hill to one side, the draw of this white sand beach has steadily grown in the legion of avid beach buffs. The popularity of this beach has triggered cruises from across the Andaman Sea. Travelers’s cruise over a few days from Thailand to experience the magic of this beach. A wide beach that runs over a few kilometers, Radha Nagar is simply stunning. It lends itself to a variety of activities. Overnight at the Hotel."
      },
      day4:{
        day4Title:"Day 4: Havelock – Port Blair- Ross Island",
        day4Desc:"After breakfast transfer to Havelock Jetty. From here sail to Port Blair on board Cruise. Upon Arrival check into the hotel. Overnight stay at the Hotel. After reached to port Blair embark on a day long three island cruises. One of the most sought-after activities. It covers a panorama of seven harbors that include the Ross Island. It leaves a lasting impression. && Visit Ross Island. Once the administrative headquarters of the British and capital of these Islands, the island is now under the Indian Navy. It stands now as a ruin of the bygone days with the old structures almost in debris. There is a small museum named Smritika, it holds photographs and the other antiques of the Britishers relevant to these islands. The island is famous for friendly flocks of Deer and lush green walking trails."
      },
      day5:{
        day5Title:"Day 5: Depart from Port Blair ",
        day5Desc:"Drop to Airport / Harbor for Return journey with a wonderful holiday memory. "
      }, day6:{
        day6Title:"",
        day6Desc:""
      },
      day7:{
        day7Title:"",
        day7Desc:""
      }
    },

    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
      {
        name: "jhon doe",
        rating: 5,
      },
      {
        name: "jhon doe",
        rating: 4.6,
      },
      {
        name: "jhon doe",
        rating: 5,
      },
      {
        name: "jhon doe",
        rating: 4.6,
      },
      {
        name: "jhon doe",
        rating: 4.9,
      },
      {
        name: "jhon doe",
        rating: 4.6,
      },
      {
        name: "jhon doe",
        rating: 4.9,
      },
    ],
    avgRating: 4.5,
    photo: tourImg02,
    featured: false,
  },

  {
    id: "03",
    title: "Andaman Best Seller",
    city: "Port Blair",
    address: '5 Nights / 6 Days | 3 Nights Port Blair/ 2 Nights Havelock Island',
    distance: 500,
    price: 99,
    maxGroupSize: 8,
    desc: {
      day1:{
        day1Title:"Day 1: Arrival – Port Blair – Cellular Jail – Light & Sound Show",
        day1Desc:"Arrival to Port Blair. Our representative will meet and assist you at the Veer Savarkar Airport. Board the waiting vehicles, drive to your hotel and check in. First half of the day is at leisure. Later in the afternoon visit Cellular jail followed by light and sound show. The Cellular Jail has a pride of place in India’s freedom struggle. Many a stalwart were imprisoned here. Declared a national memorial, this three-story prison constructed by Britishers in 1906, is a pilgrimage destination for freedom fighters. This colossal edifice has mutely witnessed the most treacherous of inhumane atrocities borne by the convicts, who were mostly freedom fighters. The day draws to a gentle close with the Sound and Light Show at the historic Cellular Jail which gives you a vivid experience of its strong association with the freedom fighters and their struggle for independence. Overnight at the Hotel."
      },
      day2:{
        day2Title:"Day 2: Port Blair- Havelock",
        day2Desc:"Today after breakfast you will transfer to Jetty. Head to Havelock Island on board Cruise connecting the two islands! Havelock, about 54 kilometers northeast of Port Blair, the Island is a hidden jewel. Few Indians know of this stunning beach destination in their own backyard while heading for Phuket (in Thailand) across the waters of Andaman Sea. Havelock is a serene tropical island encompassing about 86 square kilometers. Its clear waters are rated among the best in the world for snorkeling and scuba diving. Close to 1000 different species of marine life inhabit the waters of the Andaman Islands. Scuba divers can see nudibranchs, sea slugs and snails, varieties of ghost pipe fish, jaw fish and a number of different crustaceans of all shapes and sizes. With its serene beaches, 40 meters visibility underwater, colorful coral reefs and ‘far from the maddening crowd’ appeal, Havelock Island has emerged as a dream destination among discerning travelers. Arrive at Havelock Jetty, you are met and assisted by our representative. Board the waiting vehicles and drive to the hotel. Check in at the hotel. Overnight at the Hotel."
      },
      day3:{
        day3Title:"Day 3: Havelock – Radha Nagar Beach ",
        day3Desc:"Today after breakfast, proceed for Radha Nagar Beach (or Beach No. 7) is the jewel in the crown of Havelock Island. Located on the western side of the island, it stands away from the cluster. The glorious white sand beach shot to global fame when Time magazine (in 2004) rated it as the finest in Asia. With lush tropical forest rising on a hill to one side, the draw of this white sand beach has steadily grown in the legion of avid beach buffs. The popularity of this beach has triggered cruises from across the Andaman Sea. Travelers’s cruise over a few days from Thailand to experience the magic of this beach. A wide beach that runs over a few kilometers, Radha Nagar is simply stunning. It lends itself to a variety of activities. Overnight at the Hotel."
      },
      day4:{
        day4Title:"Day 4: Havelock- Port Blair",
        day4Desc:"Today in the morning have breakfast and Leisure. Afternoon Board the vehicles and drive to Havelock Jetty. From here sail to Port Blair on board Cruise. Upon arrival check into the hotel. Overnight stay at the Hotel.  && (Optional Elephanta Beach @ INR 1150/- Per Pax) Proceed for snorkeling at Elephant beach. Elephant Beach is a small pristine beach, rather remotely located, it can be reached by boat. The beach is also an extremely good place for snorkeling with an easily accessible house reef, which boasts of beautiful corals and other marine life. The beach has also emerged as Havelock’s favorite spot for scuba diving. It is tailor made to be a beginner’s dive site. "
      },
      day5:{
        day5Title:"Day 5: Port Blair-North Bay (Coral Island) – Ross Island",
        day5Desc:"Today after breakfast embark on a day long three island cruises. One of the most sought-after activities. It covers a panorama of seven harbors that include the North Bay and Ross Island. It leaves a lasting impression. Visit Ross Island. Once the administrative headquarters of the British and capital of these Islands, the island is now under the Indian Navy. It stands now as a ruin of the bygone days with the old structures almost in debris. There is a small museum named Smritika, it holds photographs and the other antiques of the Britisher’s relevant to these islands. The island is famous for friendly flocks of Deer and lush green walking trails. North Bay, the water lovers may enjoy snorkeling or take a glass bottom boat ride (Optional on direct payment) in its shallow waters with a chance to see corals up close. Return to the hotel. Overnight at the Hotel. "
      }, day6:{
        day6Title:"Day 6: Depart from Port Blair",
        day6Desc:"Drop to Airport / Harbors for Return journey with a wonderful holiday memory."
      },
      day7:{
        day7Title:"",
        day7Desc:""
      },
  },
  reviews: [
    {
      name: "jhon doe",
      rating: 4.6,
    },
    {
      name: "jhon doe",
      rating: 5,
    },
    {
      name: "jhon doe",
      rating: 4.7,
    },
    {
      name: "jhon doe",
      rating: 4.6,
    },
    {
      name: "jhon doe",
      rating: 4.9,
    },
    {
      name: "jhon doe",
      rating: 4.6,
    },
    {
      name: "jhon doe",
      rating: 4.9,
    },
    {
      name: "jhon doe",
      rating: 4.5,
    },
    {
      name: "jhon doe",
      rating: 4.9,
    },
    {
      name: "jhon doe",
      rating: 4.8,
    },
  ],
    avgRating: 4.5,
    photo: tourImg03,
    featured: true,
  },
  {
    id: "04",
    title: "Andaman Fun filled",
    city: "Port Blair",
    address: '6 Nights / 7 Days | 3 Nights Port Blair/ 2 Nights Havelock Island/ 1 Night Neil Island',
    distance: 500,
    price: 99,
    maxGroupSize: 8,
    desc: {
      day1:{
        day1Title:"Day 1: Arrival – Port Blair – Cellular Jail - Light & Sound Show",
        day1Desc:"Arrival to Port Blair. Our representative will meet and assist you at the Veer Savarkar Airport. Board the waiting vehicles, drive to your hotel and check in. Later in the afternoon + Cellular jail followed by light and sound show. The Cellular Jail has a pride of place in India’s freedom struggle. Many a stalwart were imprisoned here. Declared a national memorial, this three-story prison constructed by Britishers in 1906, is a pilgrimage destination for freedom fighters. This colossal edifice has mutely witnessed the most treacherous of inhumane atrocities borne by the convicts, who were mostly freedom fighters. The day draws to a gentle close with the Sound and Light Show at the historic Cellular Jail which gives you a vivid experience of its strong association with the freedom fighters and their struggle for independence. Overnight at the Hotel."
      },
      day2:{
        day2Title:"Day 2: Port Blair – Havelock",
        day2Desc:"Today after breakfast you will transfer to Jetty. Head to Havelock Island on board Cruise connecting the two islands! Havelock, about 54 kilometers northeast of Port Blair, the Island is a hidden jewel. Few Indians know of this stunning beach destination in their own backyard while heading for Phuket (in Thailand) across the waters of Andaman Sea. Arrive at Havelock Jetty, you are met and assisted by our representative. Board the waiting vehicles and drive to the hotel. overnight stay at Havelock."
      },
      day3:{
        day3Title:"Day 3: Havelock- visit to Radha Nagar Beach",
        day3Desc:"Today after breakfast proceed to the Radhanagar Beach, one of the most picturesque beaches on the Islands. The beach has also been ranked 07th among the beaches of Asia by the TIME magazine. Return to hotel / resort. Stay Overnight in Havelock Island."
      },
      day4:{
        day4Title:"Day 4: Havelock to Neil Island Visit Laxmanpura and Bharatpura Beach",
        day4Desc:"Morning after breakfast exiting expedition for Neil Islands. On arrival meeting and assistance at Neil Island jetty thereafter leave by surface to Resort. proceed to Bharatpura beach and Laxmanpura beach natural bridge Overnight stay at hotel."
      },
      day5:{
        day5Title:"Day 5: Neil Island - Port Blair",
        day5Desc:"Today in the morning have breakfast and Leisure. Afternoon Board the vehicles and drive to Neil Island Jetty. From here sail to Port Blair on board Cruise. Upon Arrival check into the hotel. EVEINING free for shopping   Overnight stay at the Hotel."
      }, day6:{
        day6Title:"Day 6: Port Blair-North Bay (Corel Island) – Ross Island (Wed - Closed)",
        day6Desc:"Today after breakfast embark on a day long three island cruises. One of the most sought-after activities. It covers a panorama of seven harbors that include the North Bay and Ross Island. It leaves a lasting impression. Visit Ross Island. Once the administrative headquarters of the British and capital of these Islands, the island is now under the Indian Navy. It stands now as a ruin of the bygone days with the old structures almost in debris. There is a small museum named Smritika, it holds photographs and the other antiques of the Britishers relevant to these islands. The island is famous for friendly flocks of Deer and lush green walking trails. North Bay, the water lovers may enjoy snorkeling or take a glass bottom boat ride (Optional on direct payment) in its shallow waters with a chance to see corals up close. Return to the hotel. Overnight at the Hotel."
      },
      day7:{
        day7Title:"Day 7: Departure",
        day7Desc:"Drop to Airport / Harbor for Return journey with a wonderful holiday memory."
      },
  },
  reviews: [
    {
      name: "jhon doe",
      rating: 4.6,
    },
    {
      name: "jhon doe",
      rating: 4.6,
    },
  ],
    avgRating: 4.5,
    photo: tourImg04,
    featured: false,
  },
  {
    id: "05",
    title: "Andamans- Chota Break",
    city: "Port Blair",
    address: '3 Nights / 4 Days | 2 Nights Port Blair/ 1 Night Havelock',
    distance: 500,
    price: 99,
    maxGroupSize: 8,
    desc: {
      day1:{
        day1Title:"Day 1: Arrival- Cellular Jail + Light & Sound Show",
        day1Desc:"Arrival at Port Blair in the afternoon and transfer to respective Hotel. After lunch visit the National Memorial-Cellular Jail, designed especially for solitary confinement it earned the Islands the dreaded name of Kala Pani.  &&  In the evening witness the enthralling Sound and Light Show at Cellular Jail - where the heroic saga of the Indian freedom struggle is brought alive. Overnight at Port Blair."
      },
      day2:{
        day2Title:"Day 2: Port Blair- Havelock- Visit to Radha Nagar Beach",
        day2Desc:"After breakfast and as per ferry timing transfer to Jetty to take the onward ferry to Havelock Island. On arrival at Havelock Island, our representative will receive and escort you to check-in to the respective hotel / resort. After check in, proceed to the Radha Nagar Beach, one of the most picturesque beaches on the Islands. The beach has also been ranked 07th among the beaches of Asia by the TIME magazine. Return to hotel / resort. Overnight at Havelock Island."
      },
      day3:{
        day3Title:"Day 3: Return to Port Blair from Havelock Island",
        day3Desc:"Morning at leisure. Later in the afternoon transfer to Havelock Jetty to take the return ferry to Port Blair as per ferry timing. Upon return from Havelock Island, transfer to hotel. Overnight stay at respective Hotel / Resort at Port Blair."
      },
      day4:{
        day4Title:"Day 4: Port Blair- Drop at airport",
        day4Desc:"Drop at Airport. Return home with memories of a wonderful short break."
      },
      day5:{
        day5Title:"",
        day5Desc:" "
      }, day6:{
        day6Title:" ",
        day6Desc:" "
      },
      day7:{
        day7Title:"",
        day7Desc:""
      },
  },
  reviews: [
    {
      name: "jhon doe",
      rating: 4.6,
    },
    {
      name: "jhon doe",
      rating: 5,
    },
    {
      name: "jhon doe",
      rating: 4.8,
    },
    {
      name: "jhon doe",
      rating: 4.3,
    },
  ],
    avgRating: 4.5,
    photo: tourImg05,
    featured: true,
  },
  {
    id: "06",
    title: "Andamans Blue",
    city: "Port Blair",
    address: ' 5 Nights / 6 Days | 02 Night Port Blair/ 02 Nights Neil Island/ 01 Night Havelock',
    distance: 500,
    price: 99,
    maxGroupSize: 8,
    desc: {
      day1:{
        day1Title:"Day 1: Port Blair- Cellular Jail - Light & Sound Show",
        day1Desc:"Arrival at Port Blair in the morning/afternoon by flight and our representative will be waiting outside holding a placard with your name on it. You will be taken to your hotel where you relax and rest. Post lunch rest and later proceed to one among the most visited attraction in Andaman Islands, Cellular Jail. This jail before India's Independence hosted the most ferocious prisoners in the Andaman Islands. Today this jail stands as a national memorial. Visit to this historic attraction and know about the rich history of the Andaman Islands. Post completion of this trip, we drop you back to your hotel."
      },
      day2:{
        day2Title:"Day 2: Havelock – Visit Radha Nagar Beach",
        day2Desc:"After breakfast and as per ferry timing transfer to Jetty to take the onward ferry to Havelock Island. On arrival at Havelock Island, our representative will receive and escort you to check-in to the respective hotel / resort. After check in, proceed to the Radha Nagar Beach, one of the most picturesque beaches on the Islands. The beach has also been ranked 07th among the beaches of Asia by the TIME magazine. Return to hotel / resort. Overnight at Havelock Island."
      },
      day3:{
        day3Title:"Day 3: Neil Island- Natural Bridge",
        day3Desc:"Morning after breakfast exiting expedition for Neil Islands, On arrival meeting and assistance at Neil Island jetty thereafter leave by surface to Resort. proceed to Bharatpura beach, Natural Rock Bridge and sunset at Laxmanpur beach, Overnight stay at hotel."
      },
      day4:{
        day4Title:"Day 4: Neil Island - Day at leisure",
        day4Desc:"Today, wake up at your schedule, have breakfast leisurely and enjoy your day free style OR if you love adventure, Neil Island is a natural choice for Scuba Diving (Optional) and witness Andamans virgin marine life. We can add Scuba on demand."
      },
      day5:{
        day5Title:"Day 5: Neil Island - Port Blair- Evening free for shopping",
        day5Desc:"Today in the morning have breakfast and Leisure. Afternoon Board the vehicles and drive to Neil Island Jetty. From here sail to Port Blair on board Cruise. Upon Arrival check into the hotel. Evening free for shopping, Overnight stay at the Hotel. "
      }, day6:{
        day6Title:"Day 6: Port Blair- Departure ",
        day6Desc:"Drop to Airport for Return journey with a wonderful holiday memory "
      },
      day7:{
        day7Title:"",
        day7Desc:""
      },
  },
  reviews: [
    {
      name: "jhon doe",
      rating: 4.6,
    },
    {
      name: "jhon doe",
      rating: 5,
    },
    {
      name: "jhon doe",
      rating: 4.8,
    },
    {
      name: "jhon doe",
      rating: 4.3,
    },
    {
      name: "jhon doe",
      rating: 4.3,
    },
  ],
    avgRating: 4.5,
    photo: tourImg06,
    featured: true,
  },
  {
    id: "07",
    title: "Essence of Andamans",
    city: "Port Blair",
    address: '5 Nights / 6 Days | 02 Nights Port Blair/ 02 Nights Havelock/ 01 Night Neil Island',
    distance: 500,
    price: 99,
    maxGroupSize: 8,
    desc: {
      day1:{
        day1Title:"Day 1: Port Blair- Cellular Jail - Light & Sound Show",
        day1Desc:"Arrival to Port Blair. Our representative will meet and assist you at the Veer Savarkar Airport. Board the waiting vehicles, drive to your hotel and check in. Later in the afternoon visit Cellular jail followed by light and sound show. The Cellular Jail has a pride of place in India’s freedom struggle. Many a stalwart were imprisoned here. Declared a national memorial, this three-story prison constructed by Britishers in 1906, is a pilgrimage destination for freedom fighters. This colossal edifice has mutely witnessed the most treacherous of inhumane atrocities borne by the convicts, who were mostly freedom fighters. The day draws to a gentle close with the Sound and Light Show at the historic Cellular Jail which gives you a vivid experience of its strong association with the freedom fighters and their struggle for independence. Overnight at the Hotel."
      },
      day2:{
        day2Title:"Day 2: Port Blair- Havelock",
        day2Desc:"Today after breakfast you will transfer to Jetty. Head to Havelock Island on board Cruise connecting the two islands! Havelock, about 54 kilometers northeast of Port Blair, the Island is a hidden jewel. Few Indians know of this stunning beach destination in their own backyard while heading for Phuket (in Thailand) across the waters of Andaman Sea. Havelock is a serene tropical island encompassing about 86 square kilometers. Its clear waters are rated among the best in the world for snorkeling and scuba diving. Close to 1000 different species of marine life inhabit the waters of the Andaman Islands. Scuba divers can see nudi branchs, sea slugs and snails, varieties of ghost pipe fish, jaw fish and several different crustaceans of all shapes and sizes. With its serene beaches, 40 meters visibility underwater, colorful coral reefs and ‘far from the maddening crowd’ appeal, Havelock Island has emerged as a dream destination among discerning travelers. Arrive at Havelock Jetty, you are met and assisted by our representative. Board the waiting vehicles and drive to the hotel. Check in at the hotel. Overnight at the Hotel."
      },
      day3:{
        day3Title:"Day 3: Havelock – Visit Radha Nagar Beach",
        day3Desc:"Today after breakfast, proceed for Radha Nagar Beach (or Beach No. 7) is the jewel in the crown of Havelock Island. Located on the western side of the island, it stands away from the cluster. The glorious white sand beach shot to global fame when Time magazine (in 2004) rated it as the finest in Asia. With lush tropical forest rising on a hill to one side, the draw of this white sand beach has steadily grown in the legion of avid beach buffs. The popularity of this beach has triggered cruises from across the Andaman Sea. Travelers’s cruise over a few days from Thailand to experience the magic of this beach. A wide beach that runs over a few kilometers, Radha Nagar is simply stunning. It lends itself to a variety of activities. Overnight at the Hotel."
      },
      day4:{
        day4Title:"Day 4: Havelock - Neil Island - Visit Laxmanpur and Bharatpura Beach",
        day4Desc:"Morning after breakfast exiting expedition for Neil Islands, on arrival meeting and assistance at Neil Island jetty thereafter drive towards your Resort, check in and experience your resort. After having your lunch at your resort, proceed to Bharatpura beach to try your hand in adventure activities and Laxmanpur beach for sunset experience, Overnight stay at hotel."
      },
      day5:{
        day5Title:"Day 5: Neil Island - Port Blair",
        day5Desc:"Today in the morning have breakfast and Leisure. Afternoon Board the vehicles and drive to Neil Island Jetty. From here sail to Port Blair on board Cruise. Upon Arrival check into the hotel. Evening free for shopping, Overnight stay at the Hotel."
      }, day6:{
        day6Title:"Day 6: Departure from Port Blair",
        day6Desc:"Drop to Airport / Harbor for Return journey with wonderful holiday memories "
      },
      day7:{
        day7Title:"",
        day7Desc:""
      },
  },
  reviews: [
    {
      name: "jhon doe",
      rating: 4.6,
    },
    {
      name: "jhon doe",
      rating: 5,
    },
    {
      name: "jhon doe",
      rating: 4.8,
    },
    {
      name: "jhon doe",
      rating: 4.8,
    },
  ],
    avgRating: 4.5,
    photo: tourImg07,
    featured: true,
  },
  {
    id: "08",
    title: "Andamans- Iconic Nature",
    city: "Port Blair",
    address: '6 Nights / 7 Days | 3 Nights Port Blair with Barathang + 2 Nights Havelock Island + 01 Night Neil Island',
    distance: 500,
    price: 99,
    maxGroupSize: 8,
    desc: {
      day1:{
        day1Title:"Day 1: Arrival –Chidiya Tapu - Cellular Jail Light & Sound Show",
        day1Desc:"Arrival at Port Blair in the morning and transfer to respective Hotel. After check-in proceed for Chidiya Tapu- The Southernmost tip of South Andaman bout 31 kms from Port Blair- generally known as the Bird Island covered with lush green mangroves and beautiful beach with breath taking sunset - all of which creates a mesmerizing environment. Optional activities - Swimming, Trekking & Picnic.  &&  Later proceed to the National Monument the Cellular Jail and witness the saga of the Indian Freedom struggle through the Light and Sound Show, held within the same complex. Return to Hotel. Stay Overnight in Port Blair."
      },
      day2:{
        day2Title:"Day 2: Port Blair- Ferry to Havelock Island",
        day2Desc:"After breakfast and as per ferry timing transfer to Jetty to take the onward ferry to Havelock Island. On arrival at Havelock Island, our representative will receive and escort you to check-in to the respective hotel / resort. After check-in, proceed to the Radhanagar Beach, one of the most picturesque beach on the Islands. The beach has also been ranked 07th among the beaches of Asia by the TIME magazine. Return to hotel / resort. Stay Overnight in Havelock Island."
      },
      day3:{
        day3Title:"Day 3: Havelock Island- Visit to Elephant beach",
        day3Desc:"After breakfast proceed to Elephant beach (Optional Snorkeling at Elephant Beach at a supplement Cost) in a sharing fiber speed boat, this beach offers snorkelers calm blue shallow waters and is the perfect spot for beginner snorkelers. It’s also a great place to just spend time lazing on the beach. The beach is very scenic with fallen trees and white sand giving the photographer some beautiful pictures to take back home. Stay Overnight in Havelock Island."
      },
      day4:{
        day4Title:"Day 4: Havelock Island - Neil Island",
        day4Desc:"After an early breakfast and as per ferry timing proceed to the Jetty to take the Ferry to Neil Island. After check-in at hotel, proceed for sightseeing of this lush green Island is known for its plantations and tropical forests. The pristine white sandy beaches at Sitapur, Bharatpura and Laxmanpur are worth a visit. The breath-taking view of sea and the lush green forests on the island rejuvenate the senses. Return to hotel. Stay Overnight in Neil Island."
      },
      day5:{
        day5Title:"Day 5: Neil Island- ferry to Port Blair",
        day5Desc:"Morning at leisure.   Later in the afternoon transfer to Neil Island Jetty to take the return ferry to Port Blair as per ferry timing. Upon return from Havelock Island, transfer to hotel. Overnight stay at respective Hotel / Resort at Port Blair."
      }, day6:{
        day6Title:"Day 6: Port Blair- Barathang Island Day trip",
        day6Desc:"Early morning departure to Baratang Island by road (110Kms) which holds the nature’s best treasures gifted to the Islands. Limestone caves a must-see point for nature lovers & leisure travelers alike. The Road journey from Port Blair to Baratang involves crossing through creeks and dense equatorial forest. This involves a creek journey in small boats. After getting down from the boat, trek through a small village to reach the caves. Return to Port Blair. Stay Overnight in Port Blair."
      },
      day7:{
        day7Title:"Day 7: Port Blair- drop to Airport",
        day7Desc:"Drop at Airport. Return home with memories of an iconic holiday."
      },
  },
  reviews: [
    {
      name: "jhon doe",
      rating: 4.6,
    },
    {
      name: "jhon doe",
      rating: 5,
    },
    {
      name: "jhon doe",
      rating: 4.8,
    },
    {
      name: "jhon doe",
      rating: 4.8,
    },
    {
      name: "jhon doe",
      rating: 4.8,
    },
  ],
    avgRating: 4.5,
    photo: island12,
    featured: true,
  },
];

export default tours;
